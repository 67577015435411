import store from '@/store';

export const isNonDemoUser = () => {
  return store.getters['tenant/hasResetAccount'];
};
export const updateTitle =(value) =>{
  const t = document.title;
  const remT = t.replace(/\(\d+\)\s*/, '');
  const cnt = value > 0 ? `(${value}) ${remT}`: remT
  document.title = cnt;
}
